import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { BaseQuery } from '../models/query/base-query';
import { lastValueFrom, map } from 'rxjs';
import { BaseApiResponse } from '../models/bases/base-api-response';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private _baseRequestService: BaseRequestService) {}

    getUsers(query?: BaseQuery) {
        return lastValueFrom(
            this._baseRequestService
                .getJSON<BaseApiResponse<User>>('users', {
                    data: query,
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    getUserById(id: string) {
        return lastValueFrom(
            this._baseRequestService
                .getJSON<BaseApiResponse<any>>(`users/${id}`, {
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    createUser(data: User) {
        return lastValueFrom(
            this._baseRequestService
                .postJSON<BaseApiResponse<User>>('users', {
                    data: data,
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    updateUserStatus(id: string, status: string) {
        return lastValueFrom(
            this._baseRequestService
                .putJSON<BaseApiResponse<User>>(`users/${id}`, {
                    data: { status: status.toString() },
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }
    updateUserPassword(id: string, data: { password: string }) {
        return lastValueFrom(
            this._baseRequestService
                .patchJSON<BaseApiResponse<User>>(`users/${id}`, {
                    data: data,
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    updateUser(id: string, data: User) {
        return lastValueFrom(
            this._baseRequestService
                .patchJSON<BaseApiResponse<User>>(`users/${id}`, {
                    data: data,
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    deleteUser(id: string) {
        return lastValueFrom(
            this._baseRequestService
                .deleteJSON<BaseApiResponse<User>>(`users/${id}`, {
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }
}
