import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom, map } from 'rxjs';
import { BaseRequestService } from './base-request.service';
import { LocalStorageService } from './local-storage.service';
import { NavigationStart, Router } from '@angular/router';
import { LocalStorageEnum } from '../models/enums/local-storage-enum';
import { LoginResponse } from '../models/bases/login-response';
import {
    BaseApiResponse,
    ErrorResponse,
} from '../models/bases/base-api-response';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    authChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        this._isAuth
    );
    isAuth: boolean = this._isAuth;
    userRole: string = this._userRole;
    userId: string = this._userId;

    constructor(
        private _baseRequestService: BaseRequestService,
        private _localStorageService: LocalStorageService,
        private _router: Router
    ) {
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.isAuth != this._isAuth) {
                    this.markStatusChange();
                }
            }
        });
    }

    login(data: { username: string; password: string }) {
        return lastValueFrom(
            this._baseRequestService
                .postJSON<LoginResponse | ErrorResponse>('auth/login', {
                    data,
                    is_loading: true,
                    is_alert_error: true,
                })
                .pipe(
                    map((res: LoginResponse) => {
                        this._localStorageService.set(
                            LocalStorageEnum.token,
                            res.tokens.accessToken
                        );
                        this._localStorageService.set(
                            LocalStorageEnum.token_expires_at,
                            res.tokens.accessExpires
                        );
                        this._localStorageService.set(
                            LocalStorageEnum.user_role,
                            res.user.role
                        );

                        this._localStorageService.set(
                            LocalStorageEnum.username,
                            res.user.username
                        );

                        this._localStorageService.set(
                            LocalStorageEnum.user_firstname,
                            res.user.fullname
                        );

                        this._localStorageService.set(
                            LocalStorageEnum.user_id,
                            res.user._id
                        );
                        this._localStorageService.set(
                            LocalStorageEnum.refresh_token,
                            res.tokens.refreshToken
                        );
                        if (res.user.organization) {
                            this._localStorageService.set(
                                LocalStorageEnum.organization,
                                res.user.organization.name ?? ''
                            );
                        }

                        // if (
                        //   res.data.user.role === UserRoleEnum.Requester ||
                        //   res.data.user.role === UserRoleEnum.Updater
                        // ) {
                        //   this._localStorageService.set(
                        //     LocalStorageEnum.user_division_name,
                        //     res.data.user.division.name
                        //   );

                        //   this._localStorageService.set(
                        //     LocalStorageEnum.user_division_id,
                        //     res.data.user.division._id
                        //   );
                        // }

                        this.markStatusChange();
                        return res;
                    })
                )
        );
    }

    logout() {
        this._localStorageService.delete(LocalStorageEnum.token);
        this._localStorageService.delete(LocalStorageEnum.user_id);
        this._router.navigate(['/login']);
        return new Observable<string>((observer) => {
            observer.complete(); // complete function will be called when the observable is complete
            this.markStatusChange();
        });
    }

    private markStatusChange() {
        this.authChange$.next(this._isAuth);
        this.isAuth = this._isAuth;
        this.userRole = this._userRole;
        this.userId = this._userId;
    }

    private _getTokenExpiresAt = (tokenExpiresIn: number): number => {
        const currentDate = new Date();
        const currentMillisecond = currentDate.getTime();
        // convert to millisecond since response from server is in second
        const tokenExpiresInMillsecond = tokenExpiresIn * 1000;
        return currentMillisecond + tokenExpiresInMillsecond;
    };

    private get _isAuth(): boolean {
        if (this._localStorageService.get(LocalStorageEnum.token)) {
            return true;
        }
        return false;
    }

    private get _userRole(): string {
        return this._localStorageService.get(LocalStorageEnum.user_role);
    }

    private get _userId(): string {
        return this._localStorageService.get(LocalStorageEnum.user_id);
    }

    public get _tokenExpired(): boolean {
        const currentDate = new Date();
        return (
            Number(
                this._localStorageService.get(LocalStorageEnum.token_expires_at)
            ) < currentDate.getTime()
        );
    }

    public get permissions(): string[] {
        return this._localStorageService.getArray(LocalStorageEnum.permissions);
    }

    changeOwnPwd(data: { oldPassword: string; newPassword: string }) {
        return lastValueFrom(
            this._baseRequestService
                .postJSON<BaseApiResponse<any>>(`/auth/change-password`, {
                    data: data,
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }
}
